import React from 'react'

import '../styles/pagination.css'
import '../styles/app.css'
import DownloadApp from '../components/DownloadApp'

export default () => (
  <div>
    <DownloadApp/>
  </div>
)
